import * as React from "react"

function Star() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-2 h-2 md:w-3 md:h-3" fill="#EF7D01" viewBox="0 0 24 24"><
            path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
        </svg>
    )
}
export default Star
