import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const GoogleReviewsLp = (props) => {
    let reviews = props.reviews
    const [currentSlide, setCurrentSlide] = React.useState(0);

    const settingsSmall = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        customPaging: function (i) {
            return (
                <div
                    className={`h-[6px] w-7 mt-5 rounded-full bg-gray-400 mr-2 ${currentSlide === i ? 'bg-lndred' : ''}`}
                    style={{ opacity: 0.5 }}
                ></div>
            )
        },
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex)
    }

    return (
        <div className="pb-24 relative">
            <div className="container mx-auto px-5 max-w-2xl text-center leading-7">
                <div className="px-5">
                    <Slider {...settingsSmall}>
                        {reviews.map((review) => (
                            <div key={review.id} className="px-4 relative">
                                <div className="flex items-center" style={{ height: "400px" }}>
                                    <div className="pt-5">
                                        <p className="font-bold pb-5 text-2xl">{review.title}</p>
                                        <p className="text-gray-700">{review.review}</p>
                                        <div className="flex mb-2 justify-center pt-5">
                                            {[...Array(review.rating)].map((_, index) => (
                                                <svg key={index} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400 fill-current mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M10 1l2.46 6.755L19 7.755l-5.612 4.628L15.888 19 10 15.875 4.112 19l1.5-6.617L1 7.755l6.54-.19L10 1z" />
                                                </svg>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}
export default GoogleReviewsLp;