import React, { Component } from 'react'
import Faq from 'react-faq-component'

const styles = {
    bgColor: "none",
    rowTitleColor: "black",
    rowContentColor: "gray",
    arrowColor: "black",
    rowContentPaddingTop: "1%",
    rowContentPaddingBottom: "2%",
    rowContentPaddingLeft: '30px',
}

export class FaqComponent extends Component {
    render() {
        const data = {
            rows: this.props.faq.map(item => ({
                title: item.question,
                content: item.answer
            }))
        }
        return (
            <div className="">

                {this.props.hideTitle ? (
                    <></>
                ) : (
                    <p className="text-center font-semibold text-2xl lg:text-3xl pt-12 px-5">
                        Frequently Asked Questions
                    </p>
                )}

                <div className="container mx-auto flex justify-center">
                    <div className="lg:w-3/4 xl:w-1/2 mx-auto pt-5 pb-16 px-5">
                        <Faq data={data} styles={styles} />
                    </div>
                </div>
            </div>
        )
    }
}