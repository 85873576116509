import * as React from "react"

function Checkmark(props) {
    return (
        <svg fill="#FF5A30" className="w-4 h-4 mr-1 mt-1" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" stroke="#FF5A30">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z" fillRule="evenodd"></path>
            </g>
        </svg>
    )
}
export default Checkmark
