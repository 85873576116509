import * as React from "react"
// import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md"
import { wrap, increment, decrement, input, input2 } from "./numeric-input.module.css"
import ChevronUp from "../icons/chevron-up"
import ChevronDown from "../icons/chevron-down"

export function NumericInput({
  onIncrement,
  onDecrement,
  className,
  disabled,
  mobile,
  ...props
}) {
  return (
    <div>
      {!props.isYouMayNeed ?
        <div className={wrap}>
          {mobile ? <input
            disabled={disabled}
            type="number"
            className={[input2, className].join(" ")}
            {...props}
          />
            : <input
              disabled={disabled}
              type="number"
              className={[input, className].join(" ")}
              {...props}
            />}
          <button
            disabled={disabled}
            className={increment}
            aria-label="Increment"
            onClick={onIncrement}
          >
            <span>+</span>
            <ChevronUp />
          </button>
          <button
            disabled={disabled}
            className={decrement}
            aria-label="Decrement"
            onClick={onDecrement}
          >
            <span>-</span>
            <ChevronDown />
          </button>
        </div>
        :
        <div className="flex">
          <button
            disabled={disabled}
            className={decrement}
            aria-label="Decrement"
            onClick={onDecrement}
          >
            <span>-</span>
          </button>
          <input
            disabled={true}
            type="number"
            className='text-center bg-white'
            {...props}
          />

          <button
            disabled={disabled}
            className={increment}
            aria-label="Increment"
            onClick={onIncrement}
          >
            <span>+</span>
          </button>
        </div>
      }
    </div>
  )
}
